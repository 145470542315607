import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import AdminLoader from "../../components/AdminLoader";
import { APICALL } from "../../../helper/api/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toastifyError } from "../../../helper/Utility";

const InspectionList = () => {
  const location = useLocation();
  const { siteId, registerId, registerName } = location.state || {};
  const [quarterStartMonth, setQuarterStartMonth] = useState({});
  const [loading, setLoading] = useState(false);
  const [inspectionList, setInspectionList] = useState([]);
  const [headers, setHeaders] = useState([]);

  const ExcludedKeys = [];
  // Different cases for excluded keys based on registerId
  if (registerId === "6711f70f6879ffa90927dbab") { //Lifting & Rigging
    ExcludedKeys.push("userId","size", "expiryDate", "location", "makeAndModel", "testFrequencyQA", "lastTestDate", "nextTestDue");
  } else if (registerId === "6711f71c6879ffa90927dbae") {//Ladder
    ExcludedKeys.push("userId","expiryDate", "testFrequencyQA", "location", "makeAndModel", "lastTestDate", "nextTestDue");
  } else if (registerId === "6711f7236879ffa90927dbb1") {//Fire
    ExcludedKeys.push("userId","testFrequencyQA", "makeAndModel", "lastTestDate", "nextTestDue");
  } else if (registerId === "6711f72b6879ffa90927dbb4") { //Electrical
    ExcludedKeys.push("userId","swlWll", "manufacturer", "size", "expiryDate", "nextTestDue", "nextTestDue", "location");
  }

  const getInspectionList = async () => {
    setLoading(true);
    try {
      const res = await APICALL(
        "/admin/getInspectionsBySiteAndRegister",
        "Post",
        { siteId, registerId }
      );
      if (res?.status && res?.data?.length > 0) {
        const additionalExcludedKeys = [
          "_id",
          "siteId",
          "registerId",
          "status",
          "createdAt",
          "updatedAt",
          "__v",
        ];


        const allExcludedKeys = [...ExcludedKeys, ...additionalExcludedKeys];
        setHeaders(
          Object.keys(res?.data[0]).filter(
            (key) => !allExcludedKeys.includes(key)
          )
        );
        setInspectionList(res?.data);
        const initialQuarterStartMonth = res.data.map(inspection => {
          return inspection.inspectionType === "Quarterly" ? inspection.quarterStartMonth || "" : "";
        });
        setQuarterStartMonth(initialQuarterStartMonth);
      }
      setLoading(false);
    } catch (error) {
      const inspectionFields = [
        "itemDescription",
        "assetNumber",
        "swl/Wll",
        "manufacturer",
        "licenceNumber",
        "inspectionDate",
        "result",
        "qtagNumber",
        "size",
        "expiryDate",
        "location",
        "makeAndModel",
        "testFrequencyQA",
        "lastTestDate",
        "nextTestDue",
        "inspectionType",
        "quarterStartMonth",
        "inspectorName"
      ];

      const filteredFields = inspectionFields.filter(field => !ExcludedKeys.includes(field));
      setHeaders(filteredFields);

      console.error("Error fetching inspection data:", error);
      setLoading(false);
    }
  };


  useEffect(() => {
    getInspectionList();
  }, []);

  const getQuarterInfo = (dateInput) => {
    const date = new Date(dateInput);
    if (isNaN(date)) {
      throw new Error("Invalid date format");
    }

    const month = date.getMonth(); // 0-based (0 = January, 11 = December)
    let quarterInfo = { quarter: "", color: "" };

    if (month === 11 || month <= 1) {
      quarterInfo = { quarter: "1st Quarter", color: "#f00", textColor: "white" }; // Red
    } else if (month >= 2 && month <= 4) {
      quarterInfo = { quarter: "2nd Quarter", color: "#0f0", textColor: "black" }; // Green
    } else if (month >= 5 && month <= 7) {
      quarterInfo = { quarter: "3rd Quarter", color: "#4a86e8", textColor: "white" }; // Blue
    } else if (month >= 8 && month <= 10) {
      quarterInfo = { quarter: "4th Quarter", color: "#ff0", textColor: "black" }; // Yellow
    }

    return quarterInfo;
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const deleteItem = (id, type) => {
    setSelectedId(id);
    setSelectedType(type);
    setDeleteModal(true);
  };

  const handleDeleteSuccess = () => {
    getInspectionList();
  };

  const handleInputChange = (index, header, value) => {
    const updatedInspectionList = [...inspectionList];
    updatedInspectionList[index] = {
      ...updatedInspectionList[index],
      [header]: value,
    };


    if (header === "inspectionType") {
      const updatedItem = updatedInspectionList[index];
      updatedItem[header] = value;

      const inspectionDate = new Date(updatedItem.inspectionDate); // Inspection date ko fetch karte hain

      // Check karte hain ki inspectionType 'Yearly' hai ya 'Quarterly'
      if (value === "Yearly") {
        // 1 saal aage
        const nextInspection = new Date(inspectionDate.setFullYear(inspectionDate.getFullYear() + 1));
        updatedItem.nextInspection = nextInspection.toISOString().split('T')[0]; // Date ko format karte hain (YYYY-MM-DD)
      } else if (value === "Quarterly") {
        // 3 mahine aage
        const nextInspection = new Date(inspectionDate.setMonth(inspectionDate.getMonth() + 3));
        updatedItem.nextInspection = nextInspection.toISOString().split('T')[0];
      }

      setInspectionList(updatedInspectionList);
      // alert(JSON.stringify(updatedItem._id));
      handleInputBlur(index, header, value, updatedItem._id);
    }

    setInspectionList(updatedInspectionList);
  };

  const [rows, setRows] = useState([...Array(1)].map(() => ({
    siteId: siteId,
    registerId: registerId,
    itemDescription: "",
    assetNumber: "",
    inspectionDate: "",
    nextInspection: "",
    qtagNumber: "",
    swlWll: "",
    manufacturer: "",
    licenceNumber: "",
    result: "Pending",
    size: "",
    expiryDate: "",
    makeAndModel: "",
    testFrequencyQA: "",
    inspectionType: "",
    quarterStartMonth: "",
    errors: {},
  })));

  const handleNewInputChnage = (index, e) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [name]: value,
    };

    // Check if inspection type is changed
    if (name === "inspectionType") {
      updatedRows[index].showQuarterlyMonth = value === "Quarterly";
    }

    setRows(updatedRows);
  };

  const validateFields = (row) => {
    const requiredFields = [];
    if (registerId === "6711f72b6879ffa90927dbb4") {
      const requiredFields = [
        "itemDescription",
        "qtagNumber",
        "inspectionType",
      ];
    } else {
      const requiredFields = [
        "itemDescription",
        "inspectionDate",
        "qtagNumber",
        "inspectionType",
      ];
    }


    if (row.showQuarterlyMonth) {
      requiredFields.push("quarterStartMonth");
    }

    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!row[field]) {
        newErrors[field] = `${field.replace(/([A-Z])/g, ' $1')} is required`;
      }
    });

    return newErrors;
  };

  const submitData = async (index) => {
    const currentRow = rows[index];
    const errors = validateFields(currentRow);

    // Validate fields
    if (Object.keys(errors).length > 0) {
      const updatedRows = [...rows];
      updatedRows[index].errors = errors;
      setRows(updatedRows);
      return; // Stop submission if validation fails
    }

    try {
      // API call to submit the current row data
      const response = await APICALL(`/admin/addOrUpdateInspection/`, "POST", currentRow);

      if (response.status) {
        console.log("Data submitted successfully", response.data);
        setInspectionList((prevList) => [...prevList, response.data]);
        const updatedRows = [...rows];
        updatedRows[index] = {
          ...updatedRows[index],
          itemDescription: "",
          assetNumber: "",
          inspectionDate: "",
          nextInspection: "",
          qtagNumber: "",
          swlWll: "",
          manufacturer: "",
          licenceNumber: "",
          result: "Pending",
          size: "",
          expiryDate: "",
          makeAndModel: "",
          testFrequencyQA: "",
          inspectionType: "",
          quarterStartMonth: "",
          errors: {},
        };
        setRows(updatedRows);
      } else {
        toastifyError("Failed to submit data", response.message);
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message || "Something Went Wrong !!");
    }
  };


  const handleInputBlur = async (index, header, value, id) => {
    const updatedItem = { ...inspectionList[index], [header]: value, id: id };

    // Check if the inspection type is "Yearly"
    if (header === "inspectionType" && value === "Yearly") {
      updatedItem.quarterStartMonth = null; // Set quarterStartMonth to null
    }

    try {
      const response = await APICALL(`/admin/addOrUpdateInspection/`, "Post", updatedItem);
      if (response.status) {
        console.log("Data submitted successfully", response.data);
      } else {
        toastifyError("Failed to submit data", response.message);
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message || "Something Went Wrong !!");
    }
  };

  return (
    <>
      <Row>
        <Col md={3}>
          <div className="bredcrumb_img">
            <ul className="p-0">
              <li>
                <Link to="/admin/dashboard">
                  <i className="fa-solid fa-house me-2"></i>Home /
                </Link>
              </li>
              <li>
                <Link to="/admin/sites">
                  Inspection List /
                </Link>
              </li>
              <li>
                <Link className="active" to="/admin/sites">
                  {registerName}
                </Link>
              </li>
            </ul>
          </div>
          <Typography className="top_page" variant="h5">
            Inspection List
          </Typography>
        </Col>

        <Col md={9} className="text-end">
          <div className="d-flex align-items-center justify-content-end">
            <Link to="/admin/sites">
              <div className="add_new">
                <Button className="global_btn mb-3" variant="primary">
                  <i className="fa-solid fa-arrow-left me-2"></i> Back
                </Button>
              </div>
            </Link>
          </div>
        </Col>

        <Col md={3}>
          <div className="color_badge">
            <div className="qt1_color qt_box"></div>
            <div>
              <p>1st Quater</p>
              <p>December , Jan, Feb</p>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="color_badge">
            <div className="qt2_color qt_box"></div>
            <div>
              <p>2nd Quarter</p>
              <p>March , April May</p>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="color_badge">
            <div className="qt3_color qt_box"></div>
            <div>
              <p>3rd Quarter</p>
              <p>June , July August</p>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="color_badge">
            <div className="qt4_color qt_box"></div>
            <div>
              <p>4th Quarter</p>
              <p>September , October , November</p>
            </div>
          </div>
        </Col>
      </Row>

      <Paper className="table_samepattern mt-md-2 mt-3">
        <TableContainer>
          {loading ? (
            <AdminLoader />
          ) : (
            <Table className="cutoms-login-artist">
              <TableHead>
                <TableRow>
                  {headers.map((key) => (
                    <TableCell key={key}>
                      <span
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {key === "swlWll"
                          ? "Swl/Wll"
                          : key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                      </span>
                    </TableCell>
                  ))}
                  <TableCell align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {inspectionList.map((inspection, index) => (
                  <TableRow key={inspection._id}>
                    {headers.map((header) => {
                      let inputField;
                      let backgroundColor = "";
                      let textClass = "";
                      let InputColor = "";

                      if (header === "inspectionDate") {
                        if (inspection[header]) {
                          const { color, textColor } = getQuarterInfo(inspection[header]);
                          backgroundColor = color;
                          InputColor = textColor;
                        }
                      }

                      if (header === "nextInspection") {
                        if(registerId === "6711f72b6879ffa90927dbb4"){
                          if (inspection[header]) {
                            if(inspection['inspectionType'] === "Yearly"){
                              backgroundColor = "black";
                              InputColor = "White";
                            }else{
                              const { color, textColor } = getQuarterInfo(inspection[header]);
                              backgroundColor = color;
                              InputColor = textColor;
                            }
                          }
                        }else{
                          if (inspection[header]) {
                            const { color, textColor } = getQuarterInfo(inspection[header]);
                            backgroundColor = color;
                            InputColor = textColor;
                          }
                        }
                      }

                      if (header === "result") {
                        textClass = inspection[header] === "Pass" ? 'text-success' : inspection[header] === "Pending" ? 'orangeColor' : 'text-danger';
                      }

                      if (header === "nextInspection") {
                        inputField = (
                          <span>
                            {inspection[header]
                              ? new Date(inspection[header]).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric'
                              }).replace(',', '')
                              : "N/A"}
                          </span>
                        );
                      } else if (header === "result" || header === "inspectorName" || header === "qtagNumber" ) {
                        inputField = <span>{inspection[header] || "N/A"}</span>;
                      } else if (header === "inspectionType") {
                        inputField = (
                          <select
                            value={inspection[header] || ""}
                            onChange={(e) => handleInputChange(index, header, e.target.value)}
                            onBlur={(e) => handleInputBlur(index, header, e.target.value, inspection?._id)}
                          >
                            <option value="Yearly">Yearly</option>
                            <option value="Quarterly">Quarterly</option>
                          </select>
                        );
                      } else if (header === "quarterStartMonth") {
                        // Show dropdown if inspection type is "Quarterly"
                        inputField = (
                          <>
                            {inspection.inspectionType === "Quarterly" && (
                              <select
                                value={quarterStartMonth[index] || ""}
                                onChange={(e) => {
                                  setQuarterStartMonth((prev) => ({ ...prev, [index]: e.target.value }));
                                  handleInputChange(index, "quarterStartMonth", e.target.value);
                                }}
                                onBlur={(e) => handleInputBlur(index, "quarterStartMonth", e.target.value, inspection?._id)}
                              >
                                {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map(month => (
                                  <option key={month} value={month}>{month}</option>
                                ))}
                              </select>
                            )}
                          </>
                        );
                      } else if (header === "inspectionDate" || header === "nextInspection" || header === "expiryDate" || header === "lastTestDate" || header === "nextTestDue") {
                        const dateValue = inspection[header] ? new Date(inspection[header]).toISOString().split('T')[0] : "";
                        inputField = (
                          <input
                            type="date"
                            style={{ background: 'transparent', border: 'none', color: InputColor }}
                            value={dateValue}
                            onChange={(e) => handleInputChange(index, header, e.target.value)}
                            onBlur={(e) => handleInputBlur(index, header, e.target.value, inspection?._id)}
                          />
                        );
                      } else {
                        inputField = (
                          <input
                            type="text"
                            className="textInput"
                            defaultValue={inspection[header] || ""}
                            onChange={(e) => handleInputChange(index, header, e.target.value)}
                            onBlur={(e) => handleInputBlur(index, header, e.target.value, inspection?._id)}
                          />
                        );
                      }

                      return (
                        <TableCell key={header} className={textClass} style={{ backgroundColor: backgroundColor, color: InputColor }}>
                          {inputField}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right">
                      <Button variant="danger" onClick={() => deleteItem(inspection?._id, "inspection")}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}

                {rows.map((row, index) => (
                  <TableRow key={`row-${index}`}>
                    {headers.map((header) => (
                      <TableCell key={header}>
                        {header === "result" || header === "nextInspection" || header === "inspectorName" ? (
                          <span>-</span>
                        ) : header === "inspectionDate" || header === "expiryDate" || header === "lastTestDate" || header === "nextTestDue" ? (
                          <input
                            type="date"
                            name={header}
                            className="form-control"
                            value={row[header]}
                            onChange={(e) => handleNewInputChnage(index, e)}
                          />
                        ) : header === "inspectionType" ? (
                          <select
                            name={header}
                            className="form-control"
                            value={row[header]}
                            onChange={(e) => handleNewInputChnage(index, e)}
                          >
                            <option value="">Select Type</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Quarterly">Quarterly</option>
                          </select>
                        ) : header === "quarterStartMonth" ? (
                          row.inspectionType === "Quarterly" ? (
                            <select
                              name={header}
                              className="form-control"
                              value={row[header]}
                              onChange={(e) => handleNewInputChnage(index, e)}
                            >
                              <option value="">Select Month</option>
                              {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map(month => (
                                <option key={month} value={month}>{month}</option>
                              ))}
                            </select>
                          ) : (
                            <span>-</span> // Show a placeholder or dash if not quarterly
                          )
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            name={header}
                            placeholder={`Enter ${header.charAt(0).toUpperCase() + header.slice(1).replace(/([A-Z])/g, " $1")}`}
                            value={row[header]}
                            onChange={(e) => handleNewInputChnage(index, e)}
                          />
                        )}
                        <br />
                        {row.errors[header] && <span style={{ color: 'red' }}>{row.errors[header]}</span>}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button variant="success" onClick={() => submitData(index)}>Add</Button>
                    </TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>

      {deleteModal && (
        <ConfirmationModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          id={selectedId}
          type={selectedType}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}
    </>
  );
};

export default InspectionList;
